/* Base */
body.active {
  background-color: #000!important;
}
span.active {
  color: hsl(0, 0%, 28%);
  font-size: 20px!important;
  font-weight: bold!important;
  font-family: monospace;
  letter-spacing: 4px!important;
  cursor: pointer;
  text-transform: uppercase;
}

span.active {
  padding: 1px;
  background: linear-gradient(to right, hsl(0, 0%, 30%) 0, hsl(0, 0%, 100%) 10%, hsl(0, 0%, 30%) 20%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 3s infinite linear;
  cursor: pointer;
}

@keyframes shine {
  0% {
    background-position: 0px;
  }
  60% {
    background-position: 100px;
  }
  100% {
    background-position: 158px;
  }
}

span.inactive {
  color: #fff;
  text-shadow: 0 0 5px #e60e32, 0 0 10px #e60e32, 0 0 20px #e60e32;
  cursor: pointer;
}

span.inactive:hover {
  font-size: 102%;
}