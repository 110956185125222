.alert {
  position: relative;
  border: 1px solid transparent;
  background-color: #d4edda;
  border-color: #c3e6cb;
  color: #155724;
  text-align: center;
  display: flex;
  align-items: center; /* Centraliza verticalmente */
  justify-content: center; /* Centraliza horizontalmente */
  max-height: 3vh; /* Define uma altura mínima para o alerta */
  letter-spacing: 0.3vh;
  font-size: 1.5vh;
  overflow: auto;
}

.alert-success {
  background-color: #d4edda;
  border-color: #c3e6cb;
  color: #155724;
}

/* Alerta Vermelho */
.alert-danger {
  background-color: #f8d7da;
  border-color: #f5c6cb;
  color: #721c24;
}

/* Alerta Amarelo */
.alert-warning {
  background-color: #fff3cd;
  border-color: #ffeeba;
  color: #856404;
}

/* Alerta Azul */
.alert-info {
  background-color: #d1ecf1;
  border-color: #bee5eb;
  color: #0c5460;
}

.alert .btn-close {
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%); /* Centraliza verticalmente o botão de fechar */
  background: none;
  border: none;
  font-size: 1.5rem;
  line-height: 1;
  color: #000;
  cursor: pointer;
}

.alert .btn-close:hover {
  color: red;
}

.alert:hover{
  cursor: pointer;
  transform: scale(0.99);
  opacity: 0.9;
}